<template>
    <div class="container-fluid">        
        <section class="content-header">
            <div style="display: flex; justify-content: space-between;">
                <h3>Gestión de campos</h3>
                <div style="display: flex; gap: 10px;">
                    <input style="width: 250px;" v-model="nombreNuevoGrupo" type="text" class="form-control" placeholder="Nombre grupo">                   
                    <div>
                        <button class="btn  btn-light" style="border: 1px solid grey;" @click="crearGrupo">Añadir</button>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-default card-tabs">
                <div class="card-header p-1 pt-1">
                    <ul class="nav nav-pills" ref="tabHeaders">
                        <li v-for="(grupo, index) in Object.entries(grupos)" :key="grupo[0]" class="nav-item">
                            <a class="nav-link" :href="'#'+grupo[0]" @click.prevent="cambiarTab(index)"
                            :class="{ 'active': index === activeTab }">
                                {{ grupo[1].nombre }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <br>
            <div class="tab-content" ref="tabContents">
                <div v-for="(grupo, index) in Object.entries(grupos)" :key="grupo[0]" class="tab-pane container-grid"
                    :id="grupo[0]" :class="{ 'active': index === activeTab }">
                    <div>
                        <div style="width: 950px;" class="card card-secondary">
                            <div class="card-header">
                                Configuración del grupo
                            </div>
                            <div class="card-body">
                                <div style="display: flex; gap: 10px; align-items: center;">
                                    <label for="">Asignar a visita por defecto</label>
                                    <select style="width: 100px;" class="form-control" @change="cambioDefecto(grupo[1])" v-model="grupo[1].defecto_visita" id="">
                                        <option value="0">NO</option>
                                        <option value="1">SI</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div style="width: 1200px;" class="card card-info">
                            <div class="card-header">
                                Campos del grupo
                            </div>
                            <div class="card-body">
                                <div style="display: flex; gap: 10px">
                                    <input type="text" class="form-control" v-model="grupo[1].nombreNuevoCampo">
                                    <select v-model="grupo[1].tipoNuevoGrupo" class="form-control">
                                        <option v-for="tipo in Object.entries(tiposGrupo)" :key="tipo[0]" :value="tipo[0]">{{ tipo[1] }}</option>
                                    </select>
                                    <select v-model="grupo[1].tipoNuevoCampo" class="form-control">
                                        <option v-for="tipo in Object.entries(tiposCampo)" :key="tipo[0]" :value="tipo[0]">{{ tipo[1] }}</option>
                                    </select>
                                    <button class="btn btn-success btn-sm" @click="anadirNuevoGrupo(grupo[1])">Añadir</button>
                                </div>
                                <table class="table" v-if="grupo[1].campos">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Seccion</th>
                                            <th>Tipo</th>
                                            <th>Compañías</th>
                                            <th>Operario</th>
                                            <th>Obligatorio</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="campo in Object.entries(grupo[1].campos)" :key="campo[1].id_campo">
                                            <td>
                                                <input type="text" class="form-control" v-model="campo[1].nombre_campo">
                                                <input type="text" v-if="campo[1].valores[0] != ''" class="form-control" v-model="campo[1].valores">
                                            </td>
                                            <td>
                                                <select class="form-control" v-model="campo[1].tabla_campo">
                                                    <option v-for="tipo in Object.entries(tiposGrupo)" :key="tipo[0]" :value="tipo[0]">{{ tipo[1] }}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select v-model="campo[1].tipo_campo" class="form-control">
                                                    <option v-for="tipo in Object.entries(tiposCampo)" :key="tipo[0]" :value="tipo[0]">{{ tipo[1] }}</option>
                                                </select>
                                                <input type="text" class="form-control" v-model="campo[1].valor_defecto_campo" placeholder="Valor defecto">
                                            </td>
                                            <td>
                                                <MultiSelect  v-model="campo[1].companyias" :options="companias" optionLabel="nombre"
                                                optionValue="id" placeholder="Seleccione compañía" :maxSelectedLabels="2" class="multiselect-small"/>
                                            </td>
                                            <td>
                                                <select v-model="campo[1].mostrar_operario_campo" class="form-control">
                                                    <option value="0">NO</option>
                                                    <option value="1">SI</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div style="display: flex; gap: 5px; align-items: center;">
                                                    <label style="margin: 0;">Tramit.</label>
                                                    <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="campo[1].obligatorio_campo">
                                                </div>
                                                <div style="display: flex; gap: 5px; align-items: center;">
                                                    <label style="margin: 0;">Operario</label>
                                                    <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="campo[1].obligatorio_operario_campo">
                                                </div>
                                            </td>
                                            <td style="display: flex; gap: 10px">
                                                <button class="btn btn-sm btn-success" @click="modificarCampo(grupo[1], campo[1])">Guardar</button>
                                                <button class="btn btn-sm btn-danger" @click="eliminarCampo(grupo[1],campo[1])">Eliminar</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>    
</template>
<script>
import MultiSelect from 'primevue/multiselect';
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    data() {
        return {
            nombreNuevoGrupo: '',
            grupos:[],
            activeTab:0,
            tiposGrupo:[],
            tiposCampo:[],
            companias:[],
        }
    },
    components: {
        MultiSelect
    },
    methods: {
        async anadirNuevoGrupo(grupo){
            const api = new PwgsApi();
            console.log('grupo',grupo);
            if(grupo.nombreNuevoCampo == ''){
                alert('Introduzca nombre para el grupo.');
                return;
            }
            await api.post('gestor-campos/'+grupo.id_grupo+'/campos', { nombre: grupo.nombreNuevoCampo, tipo_grupo: grupo.tipoNuevoGrupo, tipo_campo: grupo.tipoNuevoCampo});
            this.obtenerCampos(grupo);
        },
        async modificarCampo(grupo, campo){
            const api = new PwgsApi();
            var body = {};
            body.nombre = campo.nombre_campo;
            var valores = '';
            for(var valor of campo.valores){
                valores += valor+';';
            }
            body.valores = valores;
            body.tipo_grupo = campo.tabla_campo;
            body.tipo_campo = campo.tipo_campo;
            body.valor_defecto = campo.valor_defecto_campo;
            body.mostrar_operario = campo.mostrar_operario_campo;
            body.obligatorio_campo = campo.obligatorio_campo;
            body.obligatorio_operario = campo.obligatorio_operario_campo;
            body.ids_companias = campo.companyias;
            await api.put('gestor-campos/'+grupo.id_grupo+'/campos/'+campo.id_campo, body);
        },
        async eliminarCampo(grupo, campo){
            const api = new PwgsApi();
            await api.delete('gestor-campos/'+grupo.id_grupo+'/campos/'+campo.id_campo);
            this.obtenerCampos(grupo);
        },
        async cambioDefecto(grupo){
            const api = new PwgsApi();
            await api.put('gestor-campos/'+grupo.id_grupo+'/asignar-visita', { valor : grupo.defecto_visita });
        },
        async crearGrupo(){
            const api = new PwgsApi();
            if(this.nombreNuevoGrupo == ''){
                alert('El nombre del grupo no puede estar vacío');
                return;
            }
            await api.post('gestor-campos', { nombre: this.nombreNuevoGrupo });
            this.obtenerGrupos();
        },
        async obtenerGrupos(){
            const api = new PwgsApi();
            this.tiposGrupo = await api.get('gestor-campos/tipos-grupos');
            this.tiposCampo = await api.get('gestor-campos/tipos-campos');
            var respcomp = await api.get('companias/simple?sortField=nombre&sortOrder=1');
            this.companias = respcomp.datos;
            const resp = await api.get('gestor-campos');
            this.grupos = resp.datos;
            for(var grupo of Object.values(this.grupos)){
                this.obtenerCampos(grupo);
            }
        },
        async obtenerCampos(grupo){
            const api = new PwgsApi();
            grupo.campos = await api.get('gestor-campos/'+grupo.id_grupo+'/campos');
            grupo.nombreNuevoCampo = '';
            grupo.tipoNuevoGrupo = 'visitadores';
            grupo.tipoNuevoCampo = 'texto';
            console.log('grupo',grupo);          
        },
        cambiarTab(index) {
            this.activeTab = index;
            this.$nextTick(() => {
                this.mostrarTab(index);
            });
        },
    },
    mounted() {
        this.obtenerGrupos();
    }
}
</script>
<style>
</style>